








































import { Component, Vue } from 'vue-property-decorator'
import { Form as ElForm } from 'element-ui'

import { UserModule } from '@/store/modules/user'
import * as AdminAction from '@/api/admin'

@Component
export default class LoginComponent extends Vue {
  private form = {
    username: '',
    password: ''
  }
  private rules = {
    username: [
      {
        required: true,
        message: '请输入用户名',
        trigger: 'blur'
      }
    ],
    password: [
      {
        required: true,
        message: '请输入密码',
        trigger: 'blur'
      }
    ]
  }

  handleSubmit (name: string) {
    (this.$refs[name] as ElForm).validate(async (valid) => {
      if (valid) {
        const res = await AdminAction.login({
          ...this.form
        })
        if (res.success) {
          this.$message.success('登录成功!')
          const data: any = {
            username: this.form.username,
            token: res.data
          }
          UserModule.login(data)
          await this.$router.push('/list')
        } else {
          this.$message.error('登录失败，请检查账号密码是否正确。')
        }
      } else {
        this.$message.error('表单填写有误!')
      }
    })
  }
}
